import { render, staticRenderFns } from "./myNew.vue?vue&type=template&id=c79b641c&scoped=true&"
import script from "./myNew.vue?vue&type=script&lang=js&"
export * from "./myNew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c79b641c",
  null
  
)

export default component.exports